import React from "react"
import { graphql } from "gatsby"
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image"
import Heading from "../../components/content/heading"
import Section from "../../components/containers/section"
import SectionContent from "../../components/containers/section-content"
import Rows from "../../components/grid/rows"
import Columns from "../../components/grid/columns"
import { Content } from "../../components/grid"
import Main from "../../components/layout/main"
import Seo from "../../components/seo/seo"
import Copy from "../../components/content/copy"
import SectionBackground from "../../components/containers/section-background"
import Dropdown from "../../components-smart/interactive/dropdown"
import CtaGroup from "@src/components/containers/cta-group"
import Button from "@src/components/interactive/button"

function ContactPage({ data, location }) {
  const {
    heading,
    seo,
    logoRow,
    columns,
    nicestSupportTeam,
    faqSection,
    testimonials,
    statisticsBlock,
  } = data.strapiPageContactUs

  const seoObj = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: location && location.pathname,
  }

  const faqStarStyle = {
    top: "50%",
    left: "50%",
    transform: "translate(520px,-480px)",
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <header>
        <Section>
          <SectionBackground>
            <div className="hero-image hero-image--careers" />
          </SectionBackground>
          <SectionContent hero>
            <Rows gap="xxl" className="mt-xxl mb-xl">
              <Columns count="2,1" alignment="center">
                <Content gap="xl">
                  <Heading level={1}>
                    Get in Touch with{" "}
                    <span className="text-gradient text-gradient__purple-blue">
                      Lunio
                    </span>
                  </Heading>
                  <Copy>
                    <p>{heading.postHeadingText}</p>
                  </Copy>
                </Content>
              </Columns>
              <Columns count="2">
                {columns.map(({ image, text, title }, index) => {
                  const imgData = getImage(image.localFile)
                  if (index == 2) return
                  return (
                    <Content key={index}>
                      <div className="mb-xs">
                        <GatsbyImage
                          image={imgData}
                          alt={image.alternativeText}
                        />
                      </div>
                      <Heading level={4}>{title}</Heading>
                      <Copy contentToParse={text} />
                    </Content>
                  )
                })}
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section>
        <SectionContent paddingTop="none" paddingBot="xs">
          <div className="py-xl border--rounded px-xxl text-gradient__midgard">
            <Rows>
              <Columns count="1" alignment="center">
                <Content paddingLeft="none" paddingRight="none">
                  <Heading className="text--color-white" alignment="center">
                    Need an instant answer? There's probably an article for
                    that. Check out our Knowledge Hub for articles on our most
                    common issues.
                  </Heading>
                </Content>
                <Content>
                  <CtaGroup alignment="center">
                    <Button
                      href="https://halo.lunio.ai/hc/en-us"
                      styletype="blank"
                      color="light"
                    >
                      Learn More
                    </Button>
                  </CtaGroup>
                </Content>
              </Columns>
            </Rows>
          </div>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent paddingTop="lg">
          <Rows>
            <Columns count="2" alignment="center">
              <Content>
                <Heading preheading={nicestSupportTeam.heading.preHeading}>
                  {nicestSupportTeam.heading.primaryHeading}
                </Heading>
                <Copy contentToParse={nicestSupportTeam.text} />
              </Content>
              <Content paddingRight="none" paddingLeft="none">
                <StaticImage
                  src="../../images/contact/customer-support.png"
                  alt="Customer Support Call"
                />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent paddingBot="xl">
          <Rows>
            <Columns count="1">
              <Content gap="xxl">
                <Heading
                  alignment="center"
                  className="mb-md"
                  preheading={faqSection.title.preHeading}
                >
                  {faqSection.title.primaryHeading}
                </Heading>
                <Dropdown defaultSelected={1} content={faqSection.faqs} />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>
    </Main>
  )
}

export const query = graphql`
  query ContactPageQuery {
    strapiPageContactUs {
      seo {
        metaTitle
        metaDesc
        shareImage {
          alternativeText
          localFile {
            publicURL
          }
        }
      }
      heading {
        headingText
        postHeadingText
      }
      columns {
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 160)
            }
          }
        }
        text
        title
      }
      nicestSupportTeam {
        heading {
          preHeading
          primaryHeading
        }
        text
      }
      faqSection {
        title {
          preHeading
          primaryHeading
        }
        faqs {
          copy: faqTabText
          title: faqTabTitle
        }
      }
      testimonials {
        testimonials {
          authorText
          link
          btnHex
          btnHexHover
          testimonialQuoteText
          logo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        testimonialsTitle {
          preHeading
          primaryHeading
        }
      }
      statisticsBlock {
        content
        heading {
          preHeading
          primaryHeading
        }
        statistics {
          diamondColor
          statDesc
          statText
          statTitleText
          includeCitation
          citationMark
          citationText
        }
      }
    }
  }
`

export default ContactPage
